import {Button, Container, Divider, FormLabel, Grid, InputAdornment, Snackbar, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, getMissingKeysFromObject} from "../../utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        // gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        borderRadius: '10px',
    },
    ShippingOrderHeading: {
        padding: '20px 20px 0',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        padding: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const StyleTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1D8B45',
    },
});

const StyleTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px', // Set your desired font size here
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#1D8B45',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#1D8B45',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#1D8B45',
    },
}));

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const VolumeWeightCalculator = () => {

    const [isCalculate, setIsCalculate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState(true);
    const [type, setType] = useState("MOVING");
    const [volumeWeightCalculatorInfo, setVolumeWeightCalculatorInfo] = useState({
        length: '10',
        width: '10',
        height: '10',
        weight: '10',
        dimensionUnit: 'in',
        weightUnit: 'lb',
        quantity: 1,
        conversionRate: 5000,
        linearRatio: 9
    });
    const [metric, setMetric] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [volumeWeightResult, setVolumeWeightResult] = useState(null);
    const [freightClassResult, setFreightClassResult] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleChangeType = (event, newValue) => {
        setType(newValue);
    }

    const handleMetric = (event) => {
        const {checked} = event.target;

        setMetric(checked);
        setVolumeWeightCalculatorInfo(prevState => ({
            ...prevState,
            dimensionUnit: checked ? 'cm' : 'in',
            weightUnit: checked ? 'kg' : 'lb',
            length: checked ? convertINToCM(volumeWeightCalculatorInfo?.length) : convertCMToIN(volumeWeightCalculatorInfo?.length),
            width: checked ? convertINToCM(volumeWeightCalculatorInfo?.width) : convertCMToIN(volumeWeightCalculatorInfo?.width),
            height: checked ? convertINToCM(volumeWeightCalculatorInfo?.height) : convertCMToIN(volumeWeightCalculatorInfo?.height),
            weight: checked ? convertLBToKG(volumeWeightCalculatorInfo?.weight) : convertKGToLB(volumeWeightCalculatorInfo?.weight)
        }))
    }

    const handleLength = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                height: value
            }))
        }
    }

    const handleWeight = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleConversionRate = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                conversionRate: value
            }))
        }
    }

    const handleLinearRatio = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVolumeWeightCalculatorInfo(prevState => ({
                ...prevState,
                linearRatio: value
            }))
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);

        const missingKeys = getMissingKeysFromObject({
            length: volumeWeightCalculatorInfo?.length,
            width: volumeWeightCalculatorInfo?.width,
            height: volumeWeightCalculatorInfo?.height,
            weight: volumeWeightCalculatorInfo?.weight,
            lengthUnit: volumeWeightCalculatorInfo?.dimensionUnit,
            weightUnit: volumeWeightCalculatorInfo?.weightUnit,
            quantity: volumeWeightCalculatorInfo?.quantity,
        });

        console.log('[validate] missingKeys', missingKeys);

        // setValidation(missingKeys);
        missingKeys?.length > 0 && setErrorMessage(prevState => prevState + `The following fields are missing or invalid: ${missingKeys?.join(", ")}.`);

        if (missingKeys?.length === 0) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const calculateVolumeWeight = async () => {
        try {
            setLoading(true);
            const requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/calculateLTLPackageVolumeWeight`;
            const data = {
                orderType: type,
                conversionRate: volumeWeightCalculatorInfo?.conversionRate || 5000,
                linearRatio: volumeWeightCalculatorInfo?.linearRatio || 9,
                packageData: [
                    {
                        length: volumeWeightCalculatorInfo?.length,
                        width: volumeWeightCalculatorInfo?.width,
                        height: volumeWeightCalculatorInfo?.height,
                        weight: volumeWeightCalculatorInfo?.weight,
                        lengthUnit: volumeWeightCalculatorInfo?.dimensionUnit,
                        weightUnit: volumeWeightCalculatorInfo?.weightUnit,
                        quantity: volumeWeightCalculatorInfo?.quantity,
                    }
                ]
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[calculateVolumeWeight] result', result);
            setVolumeWeightResult(result?.data);
        } catch (e) {
            console.log('[calculateVolumeWeight] error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const calculateFreightClass = async () => {
        try {
            setLoading(true);
            const requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/calculateFreightClass`;

            const lengthInches = metric ? convertCMToIN(volumeWeightCalculatorInfo?.length) : volumeWeightCalculatorInfo?.length;
            const widthInches = metric ? convertCMToIN(volumeWeightCalculatorInfo?.width) : volumeWeightCalculatorInfo?.width;
            const heightInches = metric ? convertCMToIN(volumeWeightCalculatorInfo?.height) : volumeWeightCalculatorInfo?.height;
            const weightLbs = metric ? convertKGToLB(volumeWeightCalculatorInfo?.weight) : volumeWeightCalculatorInfo?.weight;

            const data = {
                lengthInches,
                widthInches,
                heightInches,
                weightLbs
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[calculateFreightClass] result', result);
            setFreightClassResult(result?.data);
        } catch (e) {
            console.log('[calculateVolumeWeight] error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const handleClear = () => {
        setVolumeWeightResult(null);
        setFreightClassResult(null);
        setIsCalculate(false);
    }

    const handleCalculate = async () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            await calculateVolumeWeight();
            if (type === 'LTL') {
                await calculateFreightClass();
            }
            setIsCalculate(true);
        }
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Box sx={styles.ShippingOrderHeading}>
                    <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Volume Weight Calculator
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <StyleTabs
                                    value={type}
                                    onChange={handleChangeType}
                                >
                                    <StyleTab value="MOVING" label="Package"/>
                                    <StyleTab value="LTL" label="LTL"/>
                                </StyleTabs>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch
                                        checked={metric}
                                        onChange={handleMetric}
                                        disabled={isCalculate}
                                    />
                                    <Typography>
                                        Metric (kg)
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Length
                                </FormLabel>
                                <TextField
                                    value={volumeWeightCalculatorInfo?.length}
                                    onInput={handleLength}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}} // font size of input text
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {volumeWeightCalculatorInfo?.dimensionUnit}
                                            </InputAdornment>
                                        ),
                                        style: {fontSize: 14}
                                    }}
                                    disabled={isCalculate}
                                    error={!validation && volumeWeightCalculatorInfo?.length <= 0}
                                    helperText={
                                        (!validation && volumeWeightCalculatorInfo?.length <= 0) && "Missing or Invalid"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Width
                                </FormLabel>
                                <TextField
                                    value={volumeWeightCalculatorInfo?.width}
                                    onInput={handleWidth}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}} // font size of input text
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {volumeWeightCalculatorInfo?.dimensionUnit}
                                            </InputAdornment>
                                        ),
                                        style: {fontSize: 14}
                                    }}
                                    disabled={isCalculate}
                                    error={!validation && volumeWeightCalculatorInfo?.width <= 0}
                                    helperText={
                                        (!validation && volumeWeightCalculatorInfo?.width <= 0) && "Missing or Invalid"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Height
                                </FormLabel>
                                <TextField
                                    value={volumeWeightCalculatorInfo?.height}
                                    onInput={handleHeight}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}} // font size of input text
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {volumeWeightCalculatorInfo?.dimensionUnit}
                                            </InputAdornment>
                                        ),
                                        style: {fontSize: 14}
                                    }}
                                    disabled={isCalculate}
                                    error={!validation && volumeWeightCalculatorInfo?.height <= 0}
                                    helperText={
                                        (!validation && volumeWeightCalculatorInfo?.height <= 0) && "Missing or Invalid"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Weight
                                </FormLabel>
                                <TextField
                                    value={volumeWeightCalculatorInfo?.weight}
                                    onInput={handleWeight}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}} // font size of input text
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {volumeWeightCalculatorInfo?.weightUnit}
                                            </InputAdornment>
                                        ),
                                        style: {fontSize: 14}
                                    }}
                                    disabled={isCalculate}
                                    error={!validation && volumeWeightCalculatorInfo?.weight <= 0}
                                    helperText={
                                        (!validation && volumeWeightCalculatorInfo?.weight <= 0) && "Missing or Invalid"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Quantity
                                </FormLabel>
                                <TextField
                                    value={volumeWeightCalculatorInfo?.quantity}
                                    onInput={handleQuantity}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}} // font size of input text
                                    type='number'
                                    disabled={isCalculate}
                                    error={!validation && volumeWeightCalculatorInfo?.quantity <= 0}
                                    helperText={
                                        (!validation && volumeWeightCalculatorInfo?.quantity <= 0) && "Missing or Invalid"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            {
                                type === 'MOVING' ?
                                    <FormControl fullWidth>
                                        <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                            Conversion Rate
                                        </FormLabel>
                                        <TextField
                                            value={volumeWeightCalculatorInfo?.conversionRate}
                                            onInput={handleConversionRate}
                                            size='small'
                                            inputProps={{style: {fontSize: 14}}} // font size of input text
                                            type='number'
                                            disabled={isCalculate}
                                            error={!validation && volumeWeightCalculatorInfo?.conversionRate <= 0}
                                            helperText={
                                                (!validation && volumeWeightCalculatorInfo?.conversionRate <= 0) && "Missing or Invalid"
                                            }
                                        />
                                    </FormControl>
                                    :
                                    <FormControl fullWidth>
                                        <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                            Linear Ratio
                                        </FormLabel>
                                        <TextField
                                            value={volumeWeightCalculatorInfo?.linearRatio}
                                            onInput={handleLinearRatio}
                                            size='small'
                                            inputProps={{style: {fontSize: 14}}} // font size of input text
                                            type='number'
                                            disabled={isCalculate}
                                            error={!validation && volumeWeightCalculatorInfo?.linearRatio <= 0}
                                            helperText={
                                                (!validation && volumeWeightCalculatorInfo?.linearRatio <= 0) && "Missing or Invalid"
                                            }
                                        />
                                    </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                {
                                    isCalculate ?
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={handleClear}
                                        >
                                            < Typography sx={{textTransform: 'none'}}>
                                                Clear
                                            </Typography>
                                        </Button>
                                        :
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={handleCalculate}
                                            loading={loading}
                                        >
                                            < Typography sx={{textTransform: 'none'}}>
                                                Calculate
                                            </Typography>
                                        </LoadingButton>
                                }
                            </Box>
                        </Grid>
                        {
                            volumeWeightResult &&
                            <>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Total Volume
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalVolumeInch?.toFixed(2)} {type === 'MOVING' ? `in\u00B3` : `ft\u00B3`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalVolumeCM?.toFixed(2)} {type === 'MOVING' ? `cm\u00B3` : `m\u00B3`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Total Weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalWeightLB?.toFixed(2)} lb
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalWeightKG?.toFixed(2)} kg
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Total Volume Weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalVolumeWeightLB?.toFixed(2)} {type === 'MOVING' ? `cm\u00B3 \u00B7 lb` : `ft\u00B3 \u00B7 lb`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {volumeWeightResult?.totalVolumeWeightKG?.toFixed(2)} {type === 'MOVING' ? `cm\u00B3 \u00B7 kg` : `m\u00B3 \u00B7 kg`}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            freightClassResult &&
                            <>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Freight Class
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {freightClassResult?.result}
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}