import {Divider, Grid, Menu, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {Link} from "@material-ui/core";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import {mapCountryIcon, mapLTLOrderStatus} from "../../utils/Helper";
import {Fragment} from "react";

export const LTLOrderListEach = ({order_info}) => {

    console.log('[LTLOrderListEach] order info', order_info);

    return (
        <Fragment>
            <Grid item xs={8} md={8}>
                <Grid container spacing={1}>
                    <Grid item md={3} xs={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <img
                                    src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                                    alt="logo"
                                    height={40}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    height: '100%'
                                }}>
                                    <Link
                                        href={`/v2/ltl-order-dashboard/${order_info?.order_id}`}
                                        underline="none"
                                        sx={{
                                            color: '#1D8B45',
                                        }}>
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            width: '100%',
                                            color: '#1D8B45',
                                        }}>
                                            {order_info?.order_id}
                                        </Typography>
                                    </Link>
                                    <Typography style={{
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        width: '100%',
                                        fontWeight: '600'
                                    }}>
                                        {order_info?.order_number}
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        width: '100%'
                                    }}>
                                        {dayjs(order_info?.created_at).format("YYYY-MM-DD")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {order_info?.companyInfo?.business_name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    {mapCountryIcon(order_info?.send_from_country?.toUpperCase())}
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {order_info?.sender}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.send_from_country}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.pickup_area}, {order_info?.pickup_postal_code}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    {mapCountryIcon(order_info?.send_to_country?.toUpperCase())}
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {order_info?.deliver_name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.send_to_country}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.deliver_area}, {order_info?.deliver_postal_code}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                textAlign: "center",
                                color: (+order_info?.status_id) === 1 ? "#1D8B45" : (+order_info?.status_id) === -1 ? "#FF0303" : "#454545"
                            }}>
                                {mapLTLOrderStatus(+order_info?.status_id)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                textAlign: "left",
                                color: order_info?.crm_invoice_no ? "#1D8B45" : "#454545"
                            }}>
                                {order_info?.crm_invoice_no ? "Active" : "N/A"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            {
                                order_info?.icon_uri ?
                                    <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${order_info?.icon_uri}`}
                                         alt="icon"
                                         height={40}
                                         width={40}
                                    /> :
                                    <Typography style={{
                                        fontSize: '14px',
                                        // fontWeight: '600',
                                        textAlign: "left",
                                    }}>
                                        {order_info?.account_base_combine_name?.toUpperCase()}
                                    </Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                $ {(+order_info?.final_total)?.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}