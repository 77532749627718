import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {TextField, Box, Button} from "@material-ui/core";
import {green, red, blue, grey} from "@mui/material/colors";
import BackArrow from "../BackArrow";

import axios from "axios";
import {SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import Autocomplete from "@mui/material/Autocomplete";
import {useForm} from "react-hook-form";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";
import LoadingButton from "@mui/lab/LoadingButton";
import PartnerServiceFeeSettingTable from "../ServiceFeeSetting/PartnerServiceFeeSettingTable";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    customButton: {
        backgroundColor: '#FD841F',
        color: 'white',
        fontSize: '12px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FD841F',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    },
    saveButton: {
        backgroundColor: '#54B435',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#54B435',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'
        },
        marginRight: '10px'
    },
    cancelButton: {
        backgroundColor: '#FF0032',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FF0032',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    }
}));

const PartnerDetails = () => {
    const classes = useStyles();

    const [partnerList, setPartnerList] = useState([]);

    const [partnerId] = useState(useParams().id);
    const [partnerDetails, setPartnerDetails] = useState({});
    const [initialPartnerDetails, setInitialPartnerDetails] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState("");
    const [statusMessageImportant, setStatusMessageImportant] = useState("");

    const [canEditApiStatus, setCanEditApiStatus] = useState(false);
    const [canEditApiKey, setCanEditApiKey] = useState(false);
    const [canEditTestApiKey, setCanEditTestApiKey] = useState(false);

    const [canEditRating, setCanEditRating] = useState(false);
    const [canEditBuyShipping, setCanEditBuyShipping] = useState(false);
    const [canEditCreditOrder, setCanCreditOrder] = useState(false);
    const [isMovingPartner, setIsMovingPartner] = useState(false);
    const [partnerOPStatus, setPartnerOPStatus] = useState(false);
    const [canEditOverLimit, setCanEditOverLimit] = useState(false);

    const [canEditServiceFee, setCanEditServiceFee] = useState(false);
    const [canEditUPSServiceFeeRate, setCanEditUPSServiceFeeRate] = useState(false);
    const [canEditFedExServiceFeeRate, setCanEditFedExServiceFeeRate] = useState(false);
    const [canEditCanparServiceFeeRate, setCanEditCanparServiceFeeRate] = useState(false);
    const [canEditCanadaPostServiceFeeRate, setCanEditCanadaPostServiceRate] = useState(false);

    const [canEditDefaultLTLServiceFeeRate, setCanEditDefaultLTLServiceFeeRate] = useState(false);
    const [canEditABFLTLServiceRate, setCanEditABFLTLServiceRate] = useState(false);
    const [canEditManitoulinLTLServiceFeeRate, setCanEditManitoulinLTLServiceFeeRate] = useState(false);

    const [canEditDefaultBrokerageFeeRate, setCanEditDefaultBrokerageFeeRate] = useState(false);

    const [canEditParentPartner, setCanEditParentPartner] = useState(false);
    const [canEditParentPartnerId, setCanEditParentPartnerId] = useState(false);

    const [creditCard, setCreditCard] = useState(null);
    const [isAddCreditCard, setIsAddCreditCard] = useState(false);
    const [loadingCreditCard, setLoadingCreditCard] = useState(false);

    useEffect(() => {
        const getPartnerDetails = async () => {
            try {
                // Get company details from api
                const result = await axios.get(
                    // SERVICE_DAO_URL + "/organizationManagement/getPartnerInfo",
                    PARTNER_URI + "/companies/getPartnerInfo",
                    {params: {partnerId: partnerId}}
                );
                if (result.status === 200) {
                    const newPartnerDetails = result.data;
                    console.log(result.data);
                    setPartnerDetails(newPartnerDetails);
                    setInitialPartnerDetails(newPartnerDetails);
                } else {
                    throw new Error();
                }
            } catch (e) {
                return;
            }
        };
        getPartnerDetails();
    }, [partnerId]);

    const cancelChangesImportant = () => {
        setPartnerDetails(initialPartnerDetails);
    };

    const saveRating = async () => {
        setStatusMessageImportant("");
        const body = {
            canGetRating: partnerDetails.can_get_rating,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setCanGetRatingStatusForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new rating status");
            } else {
                setStatusMessageImportant("Failed to save new rating status");
                throw new Error();
            }
            setCanEditRating(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveBuyShipping = async () => {
        setStatusMessageImportant("");
        const body = {
            canBuyShipping: partnerDetails.can_buy_shipping,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setCanBuyShippingStatusForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new buy shipping Key");
            } else {
                setStatusMessageImportant("Failed to save new buy shipping Key");
                throw new Error();
            }
            setCanEditBuyShipping(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveCanCreditOrder = async () => {
        setStatusMessageImportant("");
        const body = {
            canCreditOrder: partnerDetails.can_credit_order,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setCanCreditOrder",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new can credit order status");
            } else {
                setStatusMessageImportant("Failed to save new csn credit order status");
                throw new Error();
            }
            setCanCreditOrder(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveIsMovingPartner = async () => {
        setStatusMessageImportant("");
        const body = {
            moving_op_status: partnerDetails.moving_op_status,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setMovingOPStatus",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new moving op status");
            } else {
                setStatusMessageImportant("Failed to save new moving op status");
                throw new Error();
            }
            setIsMovingPartner(false);
        } catch (e) {
            return;
        }
        return;
    };

    const savePartnerOPStatus = async () => {
        setStatusMessageImportant("");
        const body = {
            partner_op_status: partnerDetails.partner_op_status,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setPartnerOPStatus",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new moving op status");
            } else {
                setStatusMessageImportant("Failed to save new moving op status");
                throw new Error();
            }
            setPartnerOPStatus(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveOverLimit = async () => {
        setStatusMessageImportant("");
        const body = {
            overdraftLimit: partnerDetails.overdraft_limit,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setOverDraftLimitForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new over limit");
            } else {
                setStatusMessageImportant("Failed to save new over limit");
                throw new Error();
            }
            setCanEditOverLimit(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveServiceFee = async () => {
        setStatusMessageImportant("");
        const body = {
            defaultServiceFeeRate: partnerDetails.default_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setDefaultServiceFeeRateForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new service fee");
            } else {
                setStatusMessageImportant("Failed to save new service fee");
                throw new Error();
            }
            setCanEditServiceFee(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveUPSServiceFee = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.ups_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setUPSExServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new UPS service fee");
            } else {
                setStatusMessageImportant("Failed to save new UPS service fee");
                throw new Error();
            }
            setCanEditUPSServiceFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveFedExServiceFee = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.fedex_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setFedExServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new FedEx service fee");
            } else {
                setStatusMessageImportant("Failed to save new FedEx service fee");
                throw new Error();
            }
            setCanEditFedExServiceFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveCanparServiceFee = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.canpar_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setCanparServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new Canpar service fee");
            } else {
                setStatusMessageImportant("Failed to save new Canpar service fee");
                throw new Error();
            }
            setCanEditCanparServiceFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveCanadaPostServiceFee = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.canadapost_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setCanadaPostServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new CandaPost service fee");
            } else {
                setStatusMessageImportant("Failed to save new CanadaPost service fee");
                throw new Error();
            }
            setCanEditCanadaPostServiceRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveDefaultLTLServiceRate = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.default_ltl_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setDefaultLTLServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new default LTL service fee");
            } else {
                setStatusMessageImportant("Failed to save new default LTL service fee");
                throw new Error();
            }
            setCanEditDefaultLTLServiceFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveABFLTLServiceRate = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.abf_ltl_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setABFLTLServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new ABF LTL service fee");
            } else {
                setStatusMessageImportant("Failed to save new ABF LTL service fee");
                throw new Error();
            }
            setCanEditABFLTLServiceRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveManitoulinLTLServiceRate = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.manitoulin_ltl_service_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setManitoulinLTLServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new Manitoulin LTL service fee");
            } else {
                setStatusMessageImportant("Failed to save new Manitoulin LTL service fee");
                throw new Error();
            }
            setCanEditManitoulinLTLServiceFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveDefaultBrokerageServiceRate = async () => {
        setStatusMessageImportant("");
        const body = {
            serviceRate: partnerDetails.default_brokerage_fee_rate,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setDefaultBrokerageServiceRate",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new Default Brokerage service fee");
            } else {
                setStatusMessageImportant("Failed to save new Default Brokerage service fee");
                throw new Error();
            }
            setCanEditDefaultBrokerageFeeRate(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveApiKey = async () => {
        setStatusMessageImportant("");
        const body = {
            apiKey: partnerDetails.api_key,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiKeyForPartner",
                PARTNER_URI + "/companies/setApiKeyForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new API Key");
            } else {
                setStatusMessageImportant("Failed to save new API Key");
                throw new Error();
            }
            setCanEditApiKey(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveTestApiKey = async () => {
        setStatusMessageImportant("");
        const body = {
            testApiKey: partnerDetails.test_api_key,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setTestApiKeyForPartner",
                PARTNER_URI + "/companies/setTestApiKeyForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new Test API Key");
            } else {
                setStatusMessageImportant("Failed to save new Test API Key");
                throw new Error();
            }
            setCanEditTestApiKey(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveApiStatus = async () => {
        setStatusMessageImportant("");
        const body = {
            apiStatus: partnerDetails.api_status,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiStatusForPartner",
                PARTNER_URI + "/companies/setApiStatusForPartner",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new API Status");
            } else {
                setStatusMessageImportant("Failed to save new API Key");
                throw new Error();
            }
            setCanEditApiStatus(false);
        } catch (e) {
            return;
        }
        return;
    };

    const saveParentPartnerId = async () => {
        setStatusMessageImportant("");
        const body = {
            parentPartnerId: partnerDetails.parent_partner_id,
            partnerId: partnerDetails.partner_id,
        };
        try {
            // Get company details from api
            const result = await axios.post(
                // SERVICE_DAO_URL + "/organizationManagement/setApiStatusForPartner",
                PARTNER_URI + "/companies/setParentPartnerId",
                body
            );
            if (result.status === 200) {
                setStatusMessageImportant("Successfully saved new Parent Partner Id");
            } else {
                setStatusMessageImportant("Failed to save new Parent Partner Id");
                throw new Error();
            }
            setCanEditParentPartnerId(false);
            setCanEditParentPartner(false);
        } catch (e) {
            return;
        }
        return;
    };

    const getValidCreditCardList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/admin/validCreditCardList`;
        const params = {
            userId: partnerDetails.partner_id,
            roleId: 3
        }
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });
            console.log('[getValidCreditCardList] result', result);
            setCreditCard(result?.data?.[0]);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const deleteCreditCard = async () => {
        setLoadingCreditCard(true);
        const requestURL = `${NODE_ROUTE_URI}/stripe/admin/voidCardByCardId`;
        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                params: {
                    cardId: creditCard?.id,
                    userId: creditCard?.user_profile_id,
                    roleId: creditCard?.owner_role
                }
            })
            console.log('result', result);
            setStatusMessageImportant("Successfully removed credit card");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (e) {
            console.log(e.response);
            setStatusMessageImportant("Failed to remove credit card");
        } finally {
            setLoadingCreditCard(false);
        }
    }

    useEffect(() => {
        const getPartners = async () => {
            try {
                const result = await axios.get(
                    PARTNER_URI + "/companies/getAllPartnerList"
                );
                if (result.status === 200) {
                    const newPartnerList = await result.data;
                    const filterPartnerList = newPartnerList.filter(item => item.partner_id !== parseInt(partnerId));
                    setPartnerList(filterPartnerList)
                } else {
                    throw new Error({error: "Could not retrieve Partner List"})
                }
            } catch (e) {
                return
            }
        };
        getPartners();
    }, []);

    useEffect(() => {
        (async () => {
            if (partnerDetails?.partner_id) {
                await getValidCreditCardList();
            }
        })()
    }, [partnerDetails])

    const validateDefaultRate = (value, target) => {
        const number = Number(value);
        console.log(number < target)
        if (isNaN(number)) {
            setStatusMessageImportant("Default Service Fee Rate is not number");
            return false;
        }
        if (partnerDetails?.parent_partner_id !== 0) {
            if (number <= target) {
                setStatusMessageImportant("Default Service Fee Rate should be greater than 0.05")
                return false;
            }
        }

        return true;
    };

    const handleSaveDefaultServiceRate = () => {
        (validateDefaultRate(partnerDetails?.default_service_fee_rate, 0.05)) && saveServiceFee();
    }

    const handleAddCreditCard = () => {
        setIsAddCreditCard(true);
    }

    const handleCancelCreditCard = () => {
        setIsAddCreditCard(false);
    }

    return (
        <div>
            <BackArrow link={"/partnerlist"}/>
            <Box maxWidth="70%" margin="0 auto" paddingBottom={"200px"}>
                <Box mt={2}>
                    <h1>
                        {initialPartnerDetails.partner_id
                            ? "Basic Partner Info: #" + initialPartnerDetails.partner_id
                            : "Basic Partner Info"}
                    </h1>
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            {/* <th className={classes.idTH} width="10%"></th> */}
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <strong>Partner ID</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.partner_id
                                                ? partnerDetails.partner_id
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                partner_id: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Company ID</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.company_id
                                                ? partnerDetails.company_id
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                company_id: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Business Name</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.business_name
                                                ? partnerDetails.business_name
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                business_name: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>First Name</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.firstname ? partnerDetails.firstname : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                firstname: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Last Name</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.lastname ? partnerDetails.lastname : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                lastname: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Email</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={partnerDetails.email ? partnerDetails.email : ""}
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                email: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Phone</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={partnerDetails.phone ? partnerDetails.phone : ""}
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                phone: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Address</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.address ? partnerDetails.address : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                address: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Partner Email</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.partner_email
                                                ? partnerDetails.partner_email
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                partner_email: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>*/}
                        {/*        <strong>Default Service Fee Rate</strong>*/}
                        {/*    </td>*/}
                        {/*    <td>*/}
                        {/*        <Box flex="45%" width="100%">*/}
                        {/*            <TextField*/}
                        {/*                id="outlined-basic"*/}
                        {/*                value={*/}
                        {/*                    partnerDetails.default_service_fee_rate*/}
                        {/*                        ? partnerDetails.default_service_fee_rate*/}
                        {/*                        : ""*/}
                        {/*                }*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    setPartnerDetails((prev) => ({*/}
                        {/*                        ...prev,*/}
                        {/*                        default_service_fee_rate: e.target.value,*/}
                        {/*                    }));*/}
                        {/*                }}*/}
                        {/*                variant="outlined"*/}
                        {/*                fullWidth*/}
                        {/*                disabled={!canEdit}*/}
                        {/*            />*/}
                        {/*        </Box>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>
                                <strong>Current Bill Balance</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.current_bill_balance
                                                ? partnerDetails.current_bill_balance
                                                : "0.00"
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                current_bill_balance: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Admin User ID</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.admin_user_id
                                                ? partnerDetails.admin_user_id
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                admin_user_id: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Company CRM #</strong>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.company_crm_number
                                                ? partnerDetails.company_crm_number
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                company_crm_number: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEdit}
                                    />
                                </Box>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h1>API Info</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>API Status</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            API Status
                                        </InputLabel>
                                        <Select
                                            // value={partnerDetails.api_status ? true : false} // Converts 0/1 to bool
                                            value={+partnerDetails?.api_status === 1 ? 1 : 0} // Converts 0/1 to bool
                                            label="API Status"
                                            disabled={!canEditApiStatus}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    api_status: e.target.value,
                                                }))
                                            }
                                        >
                                            <MenuItem value={1}>On</MenuItem>
                                            <MenuItem value={0}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {canEditApiStatus ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                fontSize: "10px",
                                                color: "white",
                                                margin: '5px',
                                            }}
                                            variant="contained"
                                            onClick={() => saveApiStatus()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                fontSize: "10px",
                                                color: "white",
                                                margin: '5px'
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditApiStatus(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: blue[500],
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => setCanEditApiStatus(true)}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>API Key</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.api_key ? partnerDetails.api_key : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                api_key: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditApiKey}
                                        type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditApiKey ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                margin: "5px",
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveApiKey()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                margin: "5px",
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditApiKey(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: blue[500],
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => setCanEditApiKey(true)}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Test API Key</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.test_api_key
                                                ? partnerDetails.test_api_key
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                test_api_key: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditTestApiKey}
                                        type={!canEditTestApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditTestApiKey ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                margin: "5px",
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveTestApiKey()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                margin: "5px",
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditTestApiKey(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: blue[500],
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => setCanEditTestApiKey(true)}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h1>Rating and Buy Shipping</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Rating Status</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Rating Status
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails.can_get_rating ? true : false} // Converts 0/1 to bool
                                            label="Rating Status"
                                            disabled={!canEditRating}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    can_get_rating: e.target.value
                                                }))
                                            }
                                        >
                                            <MenuItem value={true}>On</MenuItem>
                                            <MenuItem value={false}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {canEditRating ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => saveRating()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditRating(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditRating(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Buy Shipping Status</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Buy Shipping Status
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails.can_buy_shipping > 0 ? 1 : 0} // Converts 0/1 to bool
                                            label="Buy Shipping Status"
                                            disabled={!canEditBuyShipping}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    can_buy_shipping: e.target.value
                                                }))
                                            }
                                        >
                                            <MenuItem value={1}>On</MenuItem>
                                            <MenuItem value={0}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {canEditBuyShipping ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => saveBuyShipping()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditBuyShipping(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditBuyShipping(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Can Credit Order</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Can Credit Order
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails.can_credit_order ? true : false} // Converts 0/1 to bool
                                            label="Can Credit Order"
                                            disabled={!canEditCreditOrder}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    can_credit_order: e.target.value
                                                }))
                                            }
                                        >
                                            <MenuItem value={true}>On</MenuItem>
                                            <MenuItem value={false}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {canEditCreditOrder ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => saveCanCreditOrder()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanCreditOrder(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setCanCreditOrder(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Is Moving Partner</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Is Moving Partner
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails?.moving_op_status === 1 ? 1 : -1} // Converts 0/1 to bool
                                            label="Is Moving Partner"
                                            disabled={!isMovingPartner}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    moving_op_status: e.target.value
                                                }))
                                            }
                                        >
                                            <MenuItem value={1}>On</MenuItem>
                                            <MenuItem value={-1}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {isMovingPartner ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => saveIsMovingPartner()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setIsMovingPartner(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setIsMovingPartner(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Partner OP Status</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Partner OP Status
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails?.partner_op_status === 1 ? 1 : -1} // Converts 0/1 to bool
                                            label="Partner OP Status"
                                            disabled={!partnerOPStatus}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    partner_op_status: e.target.value
                                                }))
                                            }
                                        >
                                            <MenuItem value={1}>On</MenuItem>
                                            <MenuItem value={-1}>Off</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {partnerOPStatus ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => savePartnerOPStatus()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setPartnerOPStatus(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setPartnerOPStatus(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Over Draft Limit</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.overdraft_limit
                                                ? partnerDetails.overdraft_limit
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                overdraft_limit: e.target.value
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditOverLimit}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditOverLimit ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => saveOverLimit()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditOverLimit(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditOverLimit(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {/*Service Fee Settings*/}
                    <>
                        <h1>Service Fee Setting</h1>
                        {statusMessageImportant}
                        <Box>
                            <PartnerServiceFeeSettingTable partnerId={partnerDetails?.partner_id} ownerRole={3} />
                        </Box>
                    </>

                    <h1>Parcel / Package Service Fee Rate</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Default Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.default_service_fee_rate ? partnerDetails?.default_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                default_service_fee_rate: e.target.value
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditServiceFee}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditServiceFee ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={handleSaveDefaultServiceRate}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white"
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditServiceFee(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white"
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditServiceFee(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>UPS Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.ups_service_fee_rate ? partnerDetails?.ups_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                ups_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditUPSServiceFeeRate}
                                        // type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditUPSServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveUPSServiceFee()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditUPSServiceFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditUPSServiceFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>FedEx Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.fedex_service_fee_rate ? partnerDetails?.fedex_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                fedex_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditFedExServiceFeeRate}
                                        // type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditFedExServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveFedExServiceFee()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditFedExServiceFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditFedExServiceFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Canpar Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.canpar_service_fee_rate
                                                ? partnerDetails?.canpar_service_fee_rate
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                canpar_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditCanparServiceFeeRate}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditCanparServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveCanparServiceFee()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditCanparServiceFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditCanparServiceFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>CanadaPost Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.canadapost_service_fee_rate
                                                ? partnerDetails?.canadapost_service_fee_rate
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                canadapost_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditCanadaPostServiceFeeRate}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditCanadaPostServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveCanadaPostServiceFee()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditCanadaPostServiceRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditCanadaPostServiceRate(true)}
                                    >
                                        update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h1>LTL Service Fee Rate</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Default LTL Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.default_ltl_service_fee_rate ? partnerDetails?.default_ltl_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                default_ltl_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditDefaultLTLServiceFeeRate}
                                        // type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditDefaultLTLServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveDefaultLTLServiceRate()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditDefaultLTLServiceFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditDefaultLTLServiceFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>ABF LTL Service Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.abf_ltl_service_fee_rate ? partnerDetails?.abf_ltl_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                abf_ltl_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditABFLTLServiceRate}
                                        // type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditABFLTLServiceRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveABFLTLServiceRate()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditABFLTLServiceRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditABFLTLServiceRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Manitoulin LTL Service Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails?.manitoulin_ltl_service_fee_rate ? partnerDetails?.manitoulin_ltl_service_fee_rate : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                manitoulin_ltl_service_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditManitoulinLTLServiceFeeRate}
                                        // type={!canEditApiKey ? "password" : undefined}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditManitoulinLTLServiceFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveManitoulinLTLServiceRate()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditManitoulinLTLServiceFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditManitoulinLTLServiceFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h1>Brokerage Service Fee Rate</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Default Brokerage Fee Rate</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <TextField
                                        id="outlined-basic"
                                        value={
                                            partnerDetails.default_brokerage_fee_rate
                                                ? partnerDetails.default_brokerage_fee_rate
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setPartnerDetails((prev) => ({
                                                ...prev,
                                                default_brokerage_fee_rate: e.target.value,
                                            }));
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!canEditDefaultBrokerageFeeRate}
                                    />
                                </Box>
                            </td>
                            <td>
                                {canEditDefaultBrokerageFeeRate ? (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: green[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => saveDefaultBrokerageServiceRate()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                marginLeft: "20px",
                                                fontSize: "10px",
                                                width: "100px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditDefaultBrokerageFeeRate(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        style={{
                                            backgroundColor: blue[500],
                                            marginLeft: "20px",
                                            fontSize: "10px",
                                            width: "100px",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={() => setCanEditDefaultBrokerageFeeRate(true)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h1>Parent Partner Id</h1>
                    {statusMessageImportant}
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH} width="20%"></th>
                            <th className={classes.idTH} width="100%"></th>
                            <th className={classes.idTH} width="10%"></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        <tr>
                            <td>
                                <Box sx={{alignItems: "center"}}>
                                    <strong>Parent Partner</strong>
                                </Box>
                            </td>
                            <td>
                                <Box flex="45%" width="100%">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Parent Partner
                                        </InputLabel>
                                        <Select
                                            value={partnerDetails.parent_partner_id === 0 ? 0 : -1} // Converts 0/1 to bool
                                            label="Parent Partner"
                                            disabled={!canEditParentPartner}
                                            onChange={(e) =>
                                                setPartnerDetails((prev) => ({
                                                    ...prev,
                                                    parent_partner_id: e.target.value,
                                                }))
                                            }
                                        >
                                            <MenuItem value={0}>Yes</MenuItem>
                                            <MenuItem value={-1}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </td>
                            <td>
                                {canEditParentPartner ? (
                                    <Box>
                                        <Button
                                            style={partnerDetails.parent_partner_id === -1 ?
                                                {
                                                    backgroundColor: grey[500],
                                                    fontSize: "10px",
                                                    color: "white",
                                                    margin: '5px'
                                                } : {
                                                    backgroundColor: green[500],
                                                    fontSize: "10px",
                                                    color: "white",
                                                    margin: '5px',
                                                }}
                                            disabled={partnerDetails.parent_partner_id === -1 ? true : false}
                                            variant="contained"
                                            onClick={() => saveParentPartnerId()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: red[500],
                                                fontSize: "10px",
                                                color: "white",
                                                margin: '5px'
                                            }}
                                            variant="contained"
                                            onClick={() => {
                                                setCanEditParentPartner(false);
                                                cancelChangesImportant();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button
                                            style={{
                                                backgroundColor: blue[500],
                                                fontSize: "10px",
                                                color: "white",
                                            }}
                                            variant="contained"
                                            onClick={() => setCanEditParentPartner(true)}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                )}
                            </td>
                        </tr>
                        {
                            partnerDetails.parent_partner_id !== 0 &&
                            <tr>
                                <td>
                                    <Box sx={{alignItems: "center"}}>
                                        <strong>Parent Partner Id</strong>
                                    </Box>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Parent Partner Id
                                            </InputLabel>
                                            <Select
                                                value={partnerDetails.parent_partner_id}
                                                label="Parent Partner Id"
                                                disabled={!canEditParentPartner}
                                                onChange={(e) =>
                                                    setPartnerDetails((prev) => ({
                                                        ...prev,
                                                        parent_partner_id: e.target.value,
                                                    }))
                                                }
                                            >
                                                {partnerList.map((partner, index) => {
                                                    return <MenuItem key={index}
                                                                     value={partner.partner_id}>{partner.partner_id}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                    <h1>Credit Card</h1>
                    {statusMessageImportant}
                    <Box>
                        <table className={classes.table}>
                            <thead>
                            <tr>
                                <th className={classes.idTH} width="20%"></th>
                                <th className={classes.idTH} width="100%"></th>
                            </tr>
                            </thead>
                            <tbody className={classes.tbody}>
                            <tr>
                                <td>
                                    <strong>Card Type</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            value={creditCard?.card_type}
                                            id="outlined-basic"
                                            variant="outlined"
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Card Number (last four digits)</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            value={creditCard?.card_last_four_digits}
                                            id="outlined-basic"
                                            variant="outlined"
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Expiry Date</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            value={creditCard?.card_expiry}
                                            id="outlined-basic"
                                            variant="outlined"
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {
                            creditCard ?
                                <LoadingButton
                                    style={{
                                        backgroundColor: red[500],
                                        fontSize: "10px",
                                        color: "white",
                                        margin: '5px',
                                    }}
                                    variant="contained"
                                    onClick={deleteCreditCard}
                                    loading={loadingCreditCard}
                                >
                                    Remove Credit Card
                                </LoadingButton>
                                :
                                <Button
                                    style={{
                                        backgroundColor: isAddCreditCard ? grey[500] : green[500],
                                        fontSize: "10px",
                                        color: "white",
                                        margin: '5px',
                                    }}
                                    variant="contained"
                                    onClick={handleAddCreditCard}
                                    disabled={isAddCreditCard}
                                >
                                    Add Credit Card
                                </Button>
                        }
                        {
                            isAddCreditCard && <CreditCardSetup userId={partnerDetails.partner_id} roleId={3}
                                                                handleCancelCreditCard={handleCancelCreditCard}/>
                        }
                    </Box>
                </Box>
            </Box>
        </div>
    )
        ;
};

export default PartnerDetails;
