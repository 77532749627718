import {Fragment} from "react";
import {Divider, FormLabel, Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

export const LTLOrderDashboardPaymentDetails = ({orderData}) => {

    console.log('[LTLOrderDashboardPaymentDetails] order data', orderData);

    return (
        <Fragment>
            {
                orderData?.backendPayments?.length > 0 ?
                    <>
                        {
                            +orderData?.payCreditAmount > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        UUCargo Credit
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        $ {+orderData?.payCreditAmount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +orderData?.payAliPayAmount > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Ali Pay
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        $ {+orderData?.payAliPayAmount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +orderData?.payWechatAmount > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        WeChat Pay
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        $ {+orderData?.payWechatAmount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +orderData?.payCreditCardAmount > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Credit Card
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        $ {+orderData?.payCreditCardAmount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +orderData?.creditCardTransactionFee > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        Transaction Fee
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        $ {+orderData?.creditCardTransactionFee?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'left'}}>
                                Payment
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'left'}}>
                                $ {(+orderData?.creditCardTransactionFee + +orderData?.final_total).toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <Typography>
                            No payment has been received for this order.
                        </Typography>
                    </Grid>
            }
            {/*<Grid item xs={12}>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        <Grid item xs={6}>*/}
            {/*            <FormControl fullWidth>*/}
            {/*                <FormLabel sx={{textAlign: 'left'}}>*/}
            {/*                    Order Price*/}
            {/*                </FormLabel>*/}
            {/*            </FormControl>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={6}>*/}
            {/*            <TextField*/}
            {/*                value={orderData?.prices?.final_total}*/}
            {/*                size='small'*/}
            {/*                disabled={true}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Fragment>
    )
}