import {Container, Grid, Typography} from "@mui/material";
import {AddressLookUp} from "./AddressLookUp";
import {CustomsBrokerage} from "./CustomsBrokerage";
import ExportEmails from "./ExportEmails";
import {VolumeWeightCalculator} from "./VolumeWeightCalculator";

export const Tools = () => {

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Tools
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AddressLookUp/>
                </Grid>
                <Grid item xs={12}>
                    <CustomsBrokerage/>
                </Grid>
                <Grid item xs={12}>
                    <VolumeWeightCalculator/>
                </Grid>
                <Grid item xs={12}>
                    <ExportEmails/>
                </Grid>
            </Grid>
        </Container>
    )
}