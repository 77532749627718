import {FormLabel, Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import {Fragment, useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {extractFullAddress} from "../../utils/Helper";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLQuoteConsignee = ({

                                      getDataFromChildComponent,
                                      triggerAction,
                                      isConfirm,
                                      validation,
                                  }) => {

    const classes = useStyles();

    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneeProvince, setConsigneeProvince] = useState();
    const [consigneeCountry, setConsigneeCountry] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();
    const [shipToCityPredictions, setShipToCityPredictions] = useState([]);
    const [shipToPostalCodePredictions, setShipToPostalCodePredictions] = useState([]);
    const [displayCity, setDisplayCity] = useState({
        description: null,
        placeId: null,
        text: null
    });
    const [displayPostalCode, setDisplayPostalCode] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const onChange = (field, value) => {
        switch (field) {
            case 'consigneeCity':
                setConsigneeCity(value);
                break;
            case 'consigneeProvince':
                setConsigneeProvince(value);
                break;
            case 'consigneeCountry':
                setConsigneeCountry(value);
                break;
            default:
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeCity = async (event, value) => {
        setDisplayCity(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setConsigneeCity(value);
        const predictionsCity = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|administrative_area_level_2');
        setShipToCityPredictions(predictionsCity);
    }

    const handleChangeCity = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);

            const city = address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region;
            const province = address?.state?.code;
            const country = address?.country?.code;

            setConsigneeCity(city);
            setConsigneeProvince(province);
            setConsigneeCountry(country);

            setDisplayCity(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region,
                province: address?.state?.code,
                country: address?.country?.code
            }))

            if (city !== displayPostalCode?.city || province !== displayPostalCode?.province || country !== displayPostalCode?.country) {
                setConsigneePostalCode('');
                setDisplayPostalCode(prevState => ({
                    ...prevState,
                    description: null,
                    placeId: null,
                    text: null
                }))
            }

        } else {
            setDisplayCity('');
        }
    }

    const handleInputChangePostalCode = async (event, value) => {
        setDisplayPostalCode(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setConsigneePostalCode(value);
        const predictionsPostalCode = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipToPostalCodePredictions(predictionsPostalCode);
    }

    const handleChangePostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setConsigneeCity(address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region);
            setConsigneeProvince(address?.state?.code);
            setConsigneeCountry(address?.country?.code);
            setDisplayCity(prevState => ({
                ...prevState,
                description: '',
                placeId: '',
                text: address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region
            }))
            setConsigneePostalCode(address?.zip);
            setDisplayPostalCode(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region,
                province: address?.state?.code,
                country: address?.country?.code
            }))
        } else {
            setConsigneePostalCode('');
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                consigneeInfo: {
                    consigneeCity,
                    consigneeProvince,
                    consigneeCountry,
                    consigneePostalCode
                }
            });
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Ship To
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            City
                        </FormLabel>
                        <Autocomplete
                            freeSolo
                            value={displayCity}
                            fullWidth
                            disabled={isConfirm}
                            options={shipToCityPredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangeCity}
                            onChange={handleChangeCity}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                                error={validation?.shipToMissing?.includes('consigneeCity')}
                                helperText={
                                    (validation?.shipToMissing?.includes('consigneeCity')) && 'Required Field'
                                }
                            />}
                            renderOption={option => (
                                <Typography>
                                    {option?.description}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Province
                        </FormLabel>
                        <CustomTextFieldPallet
                            textValue={consigneeProvince}
                            field='consigneeProvince'
                            onChange={onChange}
                            isConfirm={isConfirm}
                            error={validation?.shipToMissing?.includes('consigneeProvince')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Country
                        </FormLabel>
                        <CustomTextFieldPallet
                            textValue={consigneeCountry}
                            field='consigneeCountry'
                            onChange={onChange}
                            isConfirm={isConfirm}
                            error={validation?.shipToMissing?.includes('consigneeCountry')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Postal Code
                        </FormLabel>
                        <Autocomplete
                            freeSolo
                            value={displayPostalCode}
                            fullWidth
                            disabled={isConfirm}
                            options={shipToPostalCodePredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangePostalCode}
                            onChange={handleChangePostalCode}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                            />}
                            renderOption={option => (
                                <Typography>
                                    {option?.description}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    )
}