import {Fragment, useEffect, useRef, useState} from "react";
import {Grid, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

export const LTLOrderListInvoice = ({
                                        getDataFromChildComponent,
                                        triggerAction,
                                        isConfirm,
                                    }) => {

    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false);

    const handleChangeInvoice = (event) => {
        setIsGenerateInvoice(event?.target?.value);
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({isGenerateInvoice});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <Select
                            value={isGenerateInvoice}
                            onChange={handleChangeInvoice}
                            disabled={isConfirm}
                            size='small'>
                            <MenuItem value={true}>
                                Yes
                            </MenuItem>
                            <MenuItem value={false}>
                                No
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )
}